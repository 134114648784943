import { LoanProgramType } from "@elphi/types";
import { BaseSchemaDeal } from "@elphi/types/entities/base/deal";
import {
  hasMatchingLoanProgramTypeOrEmpty,
  hideFixNFlip,
  hideRental30
} from "../../../../components/application/sections/sections.utils";
import { OnChangeInput } from "../../../../components/form-builder/FormBuilder";
import { EntityFormFieldSpecs } from "../../../../components/form-builder/field-specs/fields.types";
import { EMPTY } from "../../../../constants/common";
import {
  addRuleToInput,
  addRulesToInputs,
  buildSection
} from "../../utils/formBuilder.utils";
import {
  achElectedIndicator,
  amortizationStartDate,
  armFinalAdjustableRateTermMonthCount,
  armInitialFixedTermMonthCount,
  armRepriceLookbackType,
  armRepriceRoundingMethodType,
  armRepriceRoundingRatePercentType,
  assignmentStatus,
  capitalStructureType,
  cashFromBorrowerAmount,
  cashToBorrowerAmount,
  ceilingPercent,
  channel,
  closingAgentFirstName,
  closingAgentLastName,
  closingAnalystUserId,
  condoCertificationFeeAmount,
  contractorReviewFeeAmount,
  creditReportFeeAmount,
  dealMetadatamilestone,
  defaultInterestRatePercent,
  defaultStatus,
  downPaymentAmount,
  eoExpirationDate,
  estimatedClosingDate,
  exceptionApprovedBy,
  exceptionCommentary,
  exceptionType,
  extendedMaturityDate,
  feasibilityReviewFeeAmount,
  finalPrice,
  firstRateChangeDate,
  floodInsurancePremiumPaymentType,
  floorPercent,
  fullyDrawnInterestOnlyPayment,
  fundingAgentEmailAddress,
  fundingDate,
  gracePeriodDays,
  haircutAmount,
  indexSourceType,
  initialAdvancedFundAmount,
  initialMonthlyPayment,
  interestAccrualMethodType,
  interestRateBuydownAmount,
  interestRateBuydownPercent,
  interestReserveDepositAmount,
  interestReserveEscrowAmount,
  interestType,
  investorIdentifier,
  investorInterestRate,
  investorLoanIdentifier,
  ioPeriodEndDate,
  lastDayOfClosingMonth,
  lateFeeAmount,
  lateFeePercent,
  lenderCounselFeeAmount,
  lenderIdentifier,
  lesserOfLotOrPurchase,
  loanAmortizationPeriodMonthCount,
  loanIdentifier,
  loanInterestOnlyPeriodMonthCount,
  loanMaturityDate,
  loanName,
  loanPackageInternationalExecutionIndicator,
  loanPackageNoteReceivedByLenderIndicator,
  loanPackageNoteTrackingNumber,
  loanPackageTrackingNumber,
  loanPaymentType,
  loanProductType,
  loanProgramType,
  loanPurposeType,
  loanTermPeriodMonthCount,
  lockPeriod,
  mailAwayIndicator,
  marginPercent,
  mersMin,
  monthlyInterestOnlyPaymentAmount,
  monthlyPrincipalAndInterestPaymentAmount,
  monthsReservesExcludingCashAtClosing,
  monthsReservesIncludingCashAtClosing,
  netWireAmount,
  newYorkProcessingFeeAmount,
  notaryExpirationDate,
  notaryUserId,
  noteRatePercent,
  operationsDepartment,
  originationFeePercent,
  originationFeesAmount,
  paidOffIndicator,
  paymentDueDay,
  perDiemPrepaidInterestAmount,
  portfolioLoanIndicator,
  prepaidInterestAmount,
  prepaymentPenaltyExpirationDate,
  prepaymentPenaltyTerm,
  prepaymentPenaltyType,
  principalBalance,
  promissoryNoteComments,
  propertyInsurancePremiumPaymentType,
  purchaserWarrantyDeedAddedIndicator,
  rateLockEndDate,
  rateLockStartDate,
  requestedLoanAmount,
  scheduledFirstPaymentDate,
  sellerHUDAddedIndicator,
  servicerAccountIdentifier,
  servicerIdentifier,
  servicerLoanIdentifier,
  servicingTransferDate,
  settlementStatementComments,
  settlementStatementFileNumber,
  shippingCompanyType,
  shortTermRentalIndicator,
  soldDate,
  submissionNotes,
  titleCommitmentAllSubjectPropertiesIncludedIndicator,
  titleCommitmentComments,
  titleCommitmentOwnership,
  titleCommitmentTitleEffectiveDate,
  titleCommitmentTitleExpirationDate,
  titleCommitmentTitleInternationalExecutionIndicator,
  titleCommitmentTitlePolicyAmount,
  titleCommitmentTitleVestingType,
  titleCompanyAddressLineText,
  titleCompanyAddressUnitIdentifier,
  titleCompanyCityName,
  titleCompanyClosingAgentContactPointEmail,
  titleCompanyClosingAgentContactPointTelephone,
  titleCompanyClosingAgentFirstName,
  titleCompanyClosingAgentLastName,
  titleCompanyName,
  titleCompanyPostalCode,
  titleCompanyStateCode,
  titleFormsComments,
  titleInsuranceUnderwriter,
  totalAdjustedMonthlyRentAmount,
  totalAsIsAppraisedValueAmount,
  totalAsIsLtvPercent,
  totalAssignmentFeeAmount,
  totalBudgetAmount,
  totalCostAmount,
  totalCostBasisAmount,
  totalDscr,
  totalFloodInsurancePremiumAmount,
  totalFloodInsurancePremiumOutstandingAmount,
  totalHOAFeesAmount,
  totalInitialFundedAmount,
  totalInitialInsuranceEscrowAmount,
  totalInitialTaxEscrowAmount,
  totalLTARVRatePercent,
  totalLTVRatePercent,
  totalLiquidAssetAmount,
  totalLiquidReservesIncludingCashAtClosing,
  totalLoanFeesAndClosingCostAmount,
  totalLtcRatePercent,
  totalLttcRatePercent,
  totalMonthlyEscrowPaymentsAmount,
  totalMonthlyHoaFeesAmount,
  totalMonthlyPaymentOfTaxesAndInsurance,
  totalNumberOfProperties,
  totalOutstandingLoanPayoffAmount,
  totalPiti,
  totalPitiaAmount,
  totalPropertyInsurancePremiumAmount,
  totalPropertyInsurancePremiumOutstandingAmount,
  totalPurchasePriceAmount,
  totalSubjectToAppraisedValueAmount,
  totalTaxAmount,
  totalValuationOrPurchasePriceAmount,
  uccExpirationDate,
  uccFiledIndicator,
  underwriterApprovalComments,
  underwriterApprovalStatus,
  warehouseBankStatusType,
  warehouseBankType,
  wireABARoutingNumber,
  wireAccountName,
  wireAccountNumber,
  wireBankName,
  wireReleaseDate
} from "../inputs/deal.inputs";
import { BaseSchemaDealSections, BaseSchemaSectionsBuilders } from "../types";

const loanInformationSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Loan Information",
      inputs: [
        loanProgramType,
        loanPurposeType,
        loanName,
        portfolioLoanIndicator,
        estimatedClosingDate,
        channel,
        operationsDepartment,
        lenderIdentifier
      ],
      fieldSpecs
    });

const dealTotalsSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Deal Totals",
      inputs: [
        totalPurchasePriceAmount,
        addRuleToInput({
          input: totalBudgetAmount,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        totalAsIsAppraisedValueAmount,
        addRuleToInput({
          input: totalSubjectToAppraisedValueAmount,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        totalLTVRatePercent,
        addRuleToInput({
          input: totalLTARVRatePercent,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        ...addRulesToInputs({
          inputs: [totalTaxAmount, totalHOAFeesAmount],
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),
        totalPropertyInsurancePremiumAmount,
        totalFloodInsurancePremiumAmount,
        addRuleToInput({
          input: totalCostAmount,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        requestedLoanAmount,
        totalOutstandingLoanPayoffAmount,
        addRuleToInput({
          input: initialAdvancedFundAmount,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        totalLoanFeesAndClosingCostAmount,
        totalValuationOrPurchasePriceAmount,
        addRuleToInput({
          input: lesserOfLotOrPurchase,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        totalNumberOfProperties,
        totalAdjustedMonthlyRentAmount,
        cashFromBorrowerAmount,
        cashToBorrowerAmount,
        totalAssignmentFeeAmount,
        addRuleToInput({
          input: totalInitialFundedAmount,
          state: r.state,
          rules: [
            {
              field: "isHidden",
              predicate: () =>
                !hasMatchingLoanProgramTypeOrEmpty({
                  loanProgramTypes: [
                    LoanProgramType.FixNFlip,
                    LoanProgramType.NewConstruction
                  ],
                  state: r.state
                })
            }
          ]
        }),
        addRuleToInput({
          input: totalCostBasisAmount,
          state: r.state,
          rules: [
            {
              field: "isHidden",
              predicate: () =>
                !hasMatchingLoanProgramTypeOrEmpty({
                  loanProgramTypes: [
                    LoanProgramType.FixNFlip,
                    LoanProgramType.NewConstruction,
                    LoanProgramType.BridgePlus
                  ],
                  state: r.state
                })
            }
          ]
        }),
        ...addRulesToInputs({
          inputs: [totalPiti, totalMonthlyHoaFeesAmount],
          state: r.state,
          rules: [
            {
              field: "isHidden",
              predicate: () =>
                !hasMatchingLoanProgramTypeOrEmpty({
                  loanProgramTypes: [LoanProgramType.Rental30],
                  state: r.state
                })
            }
          ]
        }),
        ...addRulesToInputs({
          inputs: [
            totalAsIsLtvPercent,
            totalLttcRatePercent,
            totalLtcRatePercent
          ],
          state: r.state,
          rules: [
            {
              field: "isHidden",
              predicate: () =>
                !hasMatchingLoanProgramTypeOrEmpty({
                  loanProgramTypes: [
                    LoanProgramType.FixNFlip,
                    LoanProgramType.NewConstruction
                  ],
                  state: r.state
                })
            }
          ]
        }),
        ...addRulesToInputs({
          inputs: [totalDscr, totalMonthlyPaymentOfTaxesAndInsurance],
          state: r.state,
          rules: [
            {
              field: "isHidden",
              predicate: () =>
                !hasMatchingLoanProgramTypeOrEmpty({
                  loanProgramTypes: [LoanProgramType.Rental30],
                  state: r.state
                })
            }
          ]
        })
      ],
      fieldSpecs
    });

const loanTermsSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Loan Terms",
      inputs: [
        addRuleToInput({
          input: loanProductType,
          state: r.state,
          rules: [
            {
              field: "isHidden",
              predicate: () => hideRental30(r.state)
            }
          ]
        }),
        noteRatePercent,
        estimatedClosingDate,
        rateLockStartDate,
        rateLockEndDate,
        lockPeriod,
        wireReleaseDate,
        scheduledFirstPaymentDate,
        paymentDueDay,
        lastDayOfClosingMonth,
        loanMaturityDate,
        loanTermPeriodMonthCount,
        ...addRulesToInputs({
          inputs: [
            loanAmortizationPeriodMonthCount,
            loanInterestOnlyPeriodMonthCount,
            interestAccrualMethodType,
            loanPaymentType
          ],
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),
        ...addRulesToInputs({
          inputs: [prepaymentPenaltyType, prepaymentPenaltyExpirationDate],
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),
        interestType,
        prepaymentPenaltyTerm,
        shortTermRentalIndicator,
        fundingDate,
        addRuleToInput({
          input: monthlyPrincipalAndInterestPaymentAmount,
          state: r.state,
          rules: [
            {
              field: "isHidden",
              predicate: () =>
                !hasMatchingLoanProgramTypeOrEmpty({
                  loanProgramTypes: [LoanProgramType.Rental30],
                  state: r.state
                })
            }
          ]
        }),
        monthlyInterestOnlyPaymentAmount,
        addRuleToInput({
          input: fullyDrawnInterestOnlyPayment,
          state: r.state,
          rules: [
            {
              field: "isHidden",
              predicate: () =>
                !hasMatchingLoanProgramTypeOrEmpty({
                  loanProgramTypes: [
                    LoanProgramType.FixNFlip,
                    LoanProgramType.NewConstruction,
                    LoanProgramType.BridgePlus
                  ],
                  state: r.state
                })
            }
          ]
        }),
        addRuleToInput({
          input: initialMonthlyPayment,
          state: r.state,
          rules: [
            {
              field: "isHidden",
              predicate: () =>
                !hasMatchingLoanProgramTypeOrEmpty({
                  loanProgramTypes: [
                    LoanProgramType.FixNFlip,
                    LoanProgramType.NewConstruction
                  ],
                  state: r.state
                })
            }
          ]
        }),
        ...addRulesToInputs({
          inputs: [amortizationStartDate, ioPeriodEndDate, totalPitiaAmount],
          state: r.state,
          rules: [
            {
              field: "isHidden",
              predicate: () =>
                !hasMatchingLoanProgramTypeOrEmpty({
                  loanProgramTypes: [LoanProgramType.Rental30],
                  state: r.state
                })
            }
          ]
        })
      ],
      fieldSpecs
    });

const loanTermsARMSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      isHidden: hideRental30(r.state),
      state: r.state,
      onChange: r.onChange,
      header: "Loan Terms - ARM",
      inputs: [
        firstRateChangeDate,
        indexSourceType,
        capitalStructureType,
        armRepriceRoundingRatePercentType,
        armRepriceRoundingMethodType,
        armRepriceLookbackType,
        armInitialFixedTermMonthCount,
        armFinalAdjustableRateTermMonthCount,
        ...addRulesToInputs({
          inputs: [floorPercent, ceilingPercent, marginPercent],
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        })
      ],
      fieldSpecs
    });

const loanTermsCustomSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Loan Terms - Custom",
      inputs: [
        defaultInterestRatePercent,
        lateFeePercent,
        lateFeeAmount,
        addRuleToInput({
          input: investorInterestRate,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        gracePeriodDays,
        uccFiledIndicator,
        uccExpirationDate
      ],
      fieldSpecs
    });

const titleCompanyClosingAttorneySection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Title Company",
      inputs: [
        titleInsuranceUnderwriter,
        titleCompanyName,
        titleCompanyClosingAgentFirstName,
        titleCompanyClosingAgentLastName,
        titleCompanyClosingAgentContactPointTelephone,
        titleCompanyClosingAgentContactPointEmail,
        titleCompanyAddressLineText,
        titleCompanyAddressUnitIdentifier,
        titleCompanyCityName,
        titleCompanyStateCode,
        titleCompanyPostalCode
      ],
      fieldSpecs
    });

const ledgerSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Ledger",
      inputs: [
        totalOutstandingLoanPayoffAmount,
        totalPurchasePriceAmount,
        downPaymentAmount,
        creditReportFeeAmount,
        lenderCounselFeeAmount,
        ...addRulesToInputs({
          inputs: [contractorReviewFeeAmount, feasibilityReviewFeeAmount],
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        condoCertificationFeeAmount,
        newYorkProcessingFeeAmount,
        prepaidInterestAmount,
        perDiemPrepaidInterestAmount,
        addRuleToInput({
          input: interestRateBuydownPercent,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),
        originationFeePercent,
        interestReserveEscrowAmount,
        addRuleToInput({
          input: interestRateBuydownAmount,
          state: r.state,
          rules: [
            {
              field: "isHidden",
              predicate: () =>
                !hasMatchingLoanProgramTypeOrEmpty({
                  loanProgramTypes: [LoanProgramType.Rental30],
                  state: r.state
                })
            }
          ]
        }),
        originationFeesAmount
      ],
      fieldSpecs
    });

const escrowsImpoundsSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Escrows/Impounds",
      inputs: [
        propertyInsurancePremiumPaymentType,
        totalPropertyInsurancePremiumOutstandingAmount,
        floodInsurancePremiumPaymentType,
        totalFloodInsurancePremiumOutstandingAmount,
        ...addRulesToInputs({
          inputs: [
            totalInitialInsuranceEscrowAmount,
            totalInitialTaxEscrowAmount,
            interestReserveDepositAmount
          ],
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),
        addRuleToInput({
          input: totalBudgetAmount,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        addRuleToInput({
          input: totalMonthlyEscrowPaymentsAmount,
          state: r.state,
          rules: [
            {
              field: "isHidden",
              predicate: () =>
                !hasMatchingLoanProgramTypeOrEmpty({
                  loanProgramTypes: [LoanProgramType.Rental30],
                  state: r.state
                })
            }
          ]
        })
      ],
      fieldSpecs
    });

const titleCommitmentSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Title Commitment",
      inputs: [
        titleCommitmentTitleEffectiveDate,
        titleCommitmentTitleExpirationDate,
        titleCommitmentOwnership,
        titleCommitmentTitlePolicyAmount,
        addRuleToInput({
          input: titleCommitmentTitleVestingType,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),
        titleCommitmentAllSubjectPropertiesIncludedIndicator,
        titleCommitmentTitleInternationalExecutionIndicator,
        titleCommitmentComments
      ],
      fieldSpecs
    });

const settlementStatementSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Settlement Statement",
      inputs: [
        totalLiquidAssetAmount,
        settlementStatementComments,
        cashFromBorrowerAmount,
        cashToBorrowerAmount
      ],
      fieldSpecs
    });

const titleFormsSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Title Forms",
      inputs: [
        eoExpirationDate,
        wireABARoutingNumber,
        wireAccountNumber,
        wireBankName,
        wireAccountName,
        titleFormsComments
      ],
      fieldSpecs
    });

const closingPreFundingSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Pre-Funding",
      inputs: [
        netWireAmount,
        estimatedClosingDate,
        wireReleaseDate,
        warehouseBankType,
        warehouseBankStatusType,
        haircutAmount,
        mailAwayIndicator,
        principalBalance,
        servicerAccountIdentifier
      ],
      fieldSpecs
    });

const promissoryNoteWarehouseTrackingSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Promissory Note - Warehouse Tracking",
      inputs: [
        warehouseBankType,
        closingAgentFirstName,
        closingAgentLastName,
        promissoryNoteComments,
        shippingCompanyType,
        settlementStatementFileNumber,
        loanProgramType,
        loanPackageNoteTrackingNumber
      ],
      fieldSpecs
    });

const userAssignmentSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "User Assignment",
      inputs: [notaryExpirationDate, closingAnalystUserId, notaryUserId],
      fieldSpecs
    });

const electronicExecutedLoanPackageSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Electronic Executed Loan Package",
      inputs: [
        loanPackageTrackingNumber,
        loanPackageNoteTrackingNumber,
        loanPackageInternationalExecutionIndicator,
        warehouseBankStatusType,
        achElectedIndicator,
        sellerHUDAddedIndicator,
        purchaserWarrantyDeedAddedIndicator,
        fundingAgentEmailAddress
      ],
      fieldSpecs
    });

const physicalExecutedLoanPackageSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Physical Executed Loan Package",
      inputs: [
        loanPackageTrackingNumber,
        loanPackageNoteReceivedByLenderIndicator
      ],
      fieldSpecs
    });

const underwritingDealApprovalSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Underwriting Deal Approval",
      inputs: [
        underwriterApprovalStatus,
        underwriterApprovalComments,
        submissionNotes
      ],
      fieldSpecs
    });

const exceptionSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Exceptions",
      inputs: [exceptionType, exceptionCommentary, exceptionApprovedBy],
      fieldSpecs
    });

const assignmentSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Assignment",
      inputs: [mersMin, assignmentStatus],
      fieldSpecs
    });

const servicingSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Servicing",
      inputs: [
        servicerLoanIdentifier,
        servicingTransferDate,
        servicerIdentifier,
        paidOffIndicator,
        defaultStatus,
        addRuleToInput({
          input: extendedMaturityDate,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        })
      ],
      fieldSpecs
    });

const capitalMarketsSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Capital Markets",
      inputs: [
        investorLoanIdentifier,
        soldDate,
        addRuleToInput({
          input: finalPrice,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),
        investorIdentifier
      ],
      fieldSpecs
    });

const loanOverviewSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: EMPTY,
      inputs: [
        loanProductType,
        loanPaymentType,
        loanPurposeType,
        totalPurchasePriceAmount,
        totalLTVRatePercent,
        requestedLoanAmount,
        totalAsIsAppraisedValueAmount,
        prepaymentPenaltyTerm,
        shortTermRentalIndicator,
        totalAdjustedMonthlyRentAmount
      ],
      fieldSpecs
    });

const dealDataCalendarSidebarSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: EMPTY,
      inputs: [estimatedClosingDate],
      fieldSpecs
    });

const worksheetHeaderSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      header: EMPTY,
      inputs: [
        loanIdentifier,
        requestedLoanAmount,
        estimatedClosingDate,
        loanProgramType,
        loanPurposeType,
        noteRatePercent,
        netWireAmount,
        rateLockEndDate,
        loanName,
        dealMetadatamilestone
      ],
      fieldSpecs
    });

const reservesSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Reserves",
      inputs: [
        totalLiquidReservesIncludingCashAtClosing,
        monthsReservesIncludingCashAtClosing,
        monthsReservesExcludingCashAtClosing
      ],
      fieldSpecs
    });

export const dealSectionBuilders: BaseSchemaSectionsBuilders<BaseSchemaDealSections> =
  {
    loanInformationSection,
    dealTotalsSection,
    loanTermsSection,
    loanTermsARMSection,
    loanTermsCustomSection,
    titleCompanyClosingAttorneySection,
    ledgerSection,
    escrowsImpoundsSection,
    titleCommitmentSection,
    settlementStatementSection,
    titleFormsSection,
    closingPreFundingSection,
    promissoryNoteWarehouseTrackingSection,
    userAssignmentSection,
    electronicExecutedLoanPackageSection,
    physicalExecutedLoanPackageSection,
    underwritingDealApprovalSection,
    exceptionSection,
    assignmentSection,
    servicingSection,
    capitalMarketsSection,
    loanOverviewSection,
    dealDataCalendarSidebarSection,
    reservesSection,
    worksheetHeaderSection
  };
