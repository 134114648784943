import { DeepPartial, FieldType } from "@elphi/types";
import { BaseSchemaProperty } from "@elphi/types/entities/base";
import { MofinPropertyFields } from "@elphi/types/entities/mofin/property";
import { mofinAppraisalValueDerivationType } from "@elphi/types/entities/mofin/property/mofinProperty.enumerations";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { EntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/fields.types";
import { createSpecWithFieldMeta } from "../../../../../components/form-builder/field-specs/utils/fieldMeta.utils";
import {
  booleanOptions,
  createOptionsFromMap
} from "../../../../../components/utils/formUtils";
import {
  BaseSchemaPropertyFieldSpecs,
  baseSchemaPropertySpecs
} from "../../../base/spec-files/property/property.fields";
import { integrationSpecs } from "../../../integrations/spec-files/integrationFieldsSpecs";
import { BaseSpec } from "../../../types";
import {
  mergeSpecs,
  modifyFromSpec,
  omitFromSpec
} from "../../../utils/formsSpec.utils";

type MofinSchemaPropertyFieldSpecs = EntityFormFieldSpecs<MofinPropertyFields>;

export type FullMofinSchemaPropertyFieldSpecs =
  Partial<BaseSchemaPropertyFieldSpecs> & MofinSchemaPropertyFieldSpecs;

const mofinPropertyFieldSpecs: BaseSpec<MofinSchemaPropertyFieldSpecs> = {
  aggregations: {},
  PayoffInterestDays: {
    fieldType: FieldType.Number,
    label: "Payoff Interest Days to Add",
    fieldKey: ["PayoffInterestDays"]
  },
  CanBeRebuiltIndicator: {
    fieldType: FieldType.Boolean,
    label: "Can Be Rebuilt (Legal Nonconforming Only)?",
    fieldKey: ["CanBeRebuiltIndicator"],
    options: booleanOptions
  },
  Appraisal: {
    AppraisalValueDerivationType: modifyFromSpec({
      fieldSpecs:
        baseSchemaPropertySpecs.Appraisal.AppraisalValueDerivationType,
      changes: {
        options: createOptionsFromMap(mofinAppraisalValueDerivationType)
      }
    })
  }
};

const pathsToOmitFromBase: DotNestedKeys<BaseSchemaProperty>[] = [
  "Appraisal.AppraisalValueDerivationType",
  "Appraisal.AppraisalCompletedDate",
  "Appraisal.AppraisalExpirationDate",
  "Appraisal.PropertyZoningDescription",
  "PropertyInspection.ConstructionAnalystFullName",
  "PropertyInspection.InspectionDocumentsReceivedDate",
  "PropertyInspection.InspectionInitialDelayReasonStatusType",
  "PropertyInspection.InspectionInitialRequestDate",
  "PropertyInspection.InspectionOrderProcessedDate",
  "PropertyInspection.InspectionOrderReceivedDate",
  "PropertyInspection.InspectionOrderType",
  "PropertyInspection.InspectionReportDueDate",
  "PropertyInspection.InspectionRequestOrderedDate",
  "PropertyInspection.InspectorFullName"
];

const overriddenBaseSchemaPropertySpecs: DeepPartial<
  typeof baseSchemaPropertySpecs
> = {
  aggregations: {
    LTCRatePercent: {
      calculated: modifyFromSpec({
        fieldSpecs:
          baseSchemaPropertySpecs.aggregations.LTCRatePercent?.calculated,
        changes: {
          label: "Loan to Cost (LTC) %"
        }
      }),
      override: modifyFromSpec({
        fieldSpecs:
          baseSchemaPropertySpecs.aggregations.LTCRatePercent?.override,
        changes: {
          label: "Loan to Cost (LTC) %"
        }
      }),
      focused: modifyFromSpec({
        fieldSpecs:
          baseSchemaPropertySpecs.aggregations.LTCRatePercent?.focused,
        changes: {
          label: "Loan to Cost (LTC) %"
        }
      })
    }
  }
};

const mofinPropertySpecs: FullMofinSchemaPropertyFieldSpecs = mergeSpecs(
  omitFromSpec({
    obj: baseSchemaPropertySpecs,
    paths: pathsToOmitFromBase
  }),
  overriddenBaseSchemaPropertySpecs,
  mofinPropertyFieldSpecs,
  integrationSpecs.property.FloodCertification.ServiceLink.spec
);

export const fullMofinPropertySpecs = createSpecWithFieldMeta({
  spec: mofinPropertySpecs
});
